import "./player.css";
import React from "react";
import { io } from "socket.io-client";
import YouTube, { YouTubeEvent, YouTubePlayer } from "react-youtube";
import {
  ChatMeta,
  ContentDescriptor,
  Message,
  QueueContent,
  VotedQueueContent,
} from "../api";
import { VM } from "./vm/VM";
import { useVMvalue } from "./vm/useVM";
import { Subject } from "./utils/subject";
import { Notifications } from "./view/Notifications";
import { Messages } from "./view/Messages";
import { ContentQueue } from "./tgQueue/ContentQueue";

export const endpoint =
  window.location.hostname.indexOf("localhost") >= 0
    ? "http://localhost:5001"
    : window.location.origin;
export class SessionModel {
  path: string;

  playingContent = new VM<
    | {
      id: string;
      descriptor: ContentDescriptor;
      playingId: string;
      progress: number;
      repeatSeq?: string;
      playing: boolean;
      title: string,
      userName?: string,
      userId?: number
    }
    | undefined
  >(undefined);
  upNext = new VM<QueueContent[]>([]);
  state = new VM<"initial" | "connected">("initial");

  addedSubject = new Subject<QueueContent>();
  votedSubject = new Subject<VotedQueueContent>();

  messagesPipeSubject = new Subject<Message>();

  metVM = new VM<ChatMeta | undefined>(undefined);

  socket = io(endpoint, {
    transports: ["websocket"],
    query: { player: true },
  });

  emit = (ev: string, ...args: any[]) => {
    console.log(">>", ev, args);
    this.socket.emit(ev, ...args);
  };

  onEnd = () => {
    if (this.playingContent.val) {
      this.emit("next", this.path, this.playingContent.val.playingId);
      this.playingContent.next({
        ...this.playingContent.val,
        playing: false,
      });
    }
  };

  onError = () => {
    // can't skip for first 5s to give other clients chance to report playing
    // (preventing fake request next)
    setTimeout(this.onEnd, 5000);
  };

  constructor(path: string) {
    this.path = path;

    this.socket.onAny((...e) => {
      console.log("<<", e);
    });
    this.socket.on("connect", () => {
      console.log("connect");
      this.emit("reg", path);
      // request next in case skip command lost during reconnection
      this.emit("next", path, this.playingContent.val?.playingId);
    });

    this.socket.on("queueuUpdated", (upNext: QueueContent[]) => {
      this.upNext.next(upNext);
      // first track case, next command lost during reconnect case 
      if (!this.playingContent.val?.playing) {
        this.emit("next", path, this.playingContent.val?.playingId);
      }
    });

    this.socket.on(
      "next",
      (
        next: {
          id: string;
          descriptor: ContentDescriptor;
          playingId: string;
          progress: number;
          repeatSeq?: string;
          title: string,
          userName?: string,
          userId?: number
        },
        currentPlaying
      ) => {
        if (
          next &&
          (!this.playingContent.val?.playing ||
            this.playingContent.val.playingId === currentPlaying)
        ) {
          this.playingContent.next({ ...next, playing: true });
        }
        this.state.next("connected");
      }
    );

    this.socket.on("contentAdded", (added: QueueContent) => {
      this.addedSubject.next(added);
    });

    this.socket.on("contentVoted", (voted: VotedQueueContent) => {
      this.votedSubject.next(voted);
    });

    this.socket.on("metaUpdated", (meta: ChatMeta) => {
      this.metVM.next(meta);
    });

    this.socket.on("messagesPipe", (message: Message) => {
      this.messagesPipeSubject.next(message);
    });

    this.socket.on("chatMigratedTo", (to: string) => {
      window.location.replace(to)
    });
  }

  confirmPlaying = (playingId: string) => {
    this.emit("confirmPlaying", playingId);
  };
}

export const EndIsNear = React.createContext(false)
const ModelContext = React.createContext(undefined as any as SessionModel)

function App() {
  const model = React.useMemo(
    () => new SessionModel(document.location.pathname),
    []
  );
  const state = useVMvalue(model.state);
  const playingContent = useVMvalue(model.playingContent);

  return (
    <ModelContext.Provider value={model}>
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          overflow: "hidden",
        }}
      >
        {!playingContent && (
          <div
            style={{
              justifyItems: "center",
              color: "rgba(200, 200, 200)",
              fontSize: "2em",
              margin: "auto",
              padding: 32,
            }}
          >
            {state === "initial" ? (
              "Loading..."
            ) : (
              <>
                Playlist is empty - send Youtube video to Telegram chat
                <br />
                <span style={{ color: "rgba(110, 110, 110)" }}>
                  tip: type <b>@vid Imagine Dragons</b> (or any other artist you
                  like)
                  <br />
                  and pick a video to play
                </span>
              </>
            )}
          </div>
        )}
        {playingContent && <ContentView />}


      </div>
    </ModelContext.Provider>
  );
}

export const TransitionPlayerScaleFactor = 2;
const PlaylistScaleFactor = 1.2;
const TransitionPaddings = 64;

const ContentView = React.memo(() => {
  const [endIsNear, setEndIsNear] = React.useState(false)

  return <>
    <EndIsNear.Provider value={endIsNear}>
      <div style={{
        display: "flex",
        flexShrink: 1,
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        position: "absolute",
        width: `calc((${100 / TransitionPlayerScaleFactor}vw - ${TransitionPaddings * 3}px)/${PlaylistScaleFactor})`,
        transformOrigin: 'top right',
        transform: `scale(${PlaylistScaleFactor})`,
        top: (TransitionPaddings - 16), right: TransitionPaddings, bottom: 0,
        transition: 'opacity 300ms ease-in-out',
        opacity: endIsNear ? 1 : 0
      }}>
        <Queue />
      </div>
      <div style={{
        position: "absolute",
        transformOrigin: 'top left',
        top: 0, left: 0, right: 0, bottom: 0,
        transform: ` translate(${endIsNear ? `${TransitionPaddings}px, ${TransitionPaddings}px` : '0,0'}) scale(${endIsNear ? (1 / TransitionPlayerScaleFactor) : 1})`,
        transition: 'transform 300ms ease-in-out', willChange: 'transform'
      }}>
        <PlayerAndNotifications setEndIsNear={setEndIsNear} />
      </div>
    </EndIsNear.Provider>

  </>
})

const Queue = React.memo(() => {
  const model = React.useContext(ModelContext)
  const q = useVMvalue(model.upNext)
  return <ContentQueue queue={q} />
})

const PlayerAndNotifications = React.memo(({ setEndIsNear }: { setEndIsNear: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const model = React.useContext(ModelContext)
  const playingContent = useVMvalue(model.playingContent);

  const [target, setTarget] = React.useState<YouTubePlayer>();
  const onReady = React.useCallback((event: YouTubeEvent) => {
    setTarget(event.target);
  }, []);

  const playingKey =
    (playingContent?.playingId ?? "") + (playingContent?.repeatSeq ?? "");

  React.useEffect(() => {
    if (target && playingContent?.descriptor.type === "ytb") {
      const time = playingContent.progress / 1000;
      target.loadVideoById(playingContent.descriptor.id, time);
      target.playVideo();
    }
  }, [playingKey, target]);

  const onStateChange = React.useCallback(
    (ev: YouTubeEvent<number>) => {
      if (ev.data === 1 && playingContent?.playingId) {
        model.confirmPlaying(playingContent.playingId);
      }
    },
    [playingKey]
  );

  React.useEffect(() => {
    const interval = setInterval(async () => {
      if (target) {
        const [duration, current] = await Promise.all([target.getDuration(), target.getCurrentTime()])
        setEndIsNear((duration > 0) && (duration - current) < 20)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [target])

  return <>

    {playingContent && (
      <>
        <YouTube
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
          id={"ytb_frame"}
          opts={{
            width: "100%",
            height: "100%",
            playerVars: {
              autoplay: 1, // Auto-play the video on load
              controls: 1, //  pause/play buttons in player
              showinfo: 0, //  video title
              modestbranding: 0, //  Youtube Logo
              fs: 0, //  full screen button
              iv_load_policy: 3, // Hide the Video Annotations
              cc_load_policy: 0, // Hide closed captions
              autohide: 1, //  video controls when playing
              playsinline: 1, //forbid fullscreen on ios
            } as any,
          }}
          onReady={onReady}
          onEnd={model.onEnd}
          onError={model.onError}
          onStateChange={onStateChange}
        />
        <div style={{ position: 'absolute', marginTop: 32, width: `${100 / TransitionPlayerScaleFactor}%` }}>
          <div style={{
            transform: `scale(${TransitionPlayerScaleFactor})`,
            transformOrigin: 'top left',
            color: "var(--tg-theme-text-color)",
            fontSize: "1.5em",
          }}>
            <span>{playingContent.title}</span>
            {!!playingContent.userName && <>
              <br />
              <span style={{ color: "var(--tg-theme-hint-color)", fontSize: '0.8em', marginTop: 16, display: 'inline-block' }}>{playingContent.userName}</span>
            </>}
          </div>
        </div>

      </>
    )}
    <div
      style={{
        position: "absolute",
        top: 56,
        right: 56,
        display: "flex",
      }}
    >
      <Notifications model={model} />
    </div>
    <div
      style={{
        position: "absolute",
        bottom: 56,
        left: 56,
        display: "flex",
      }}
    >
      <Messages model={model} />
    </div>
    <div
      style={{
        position: "absolute",
        top: 56,
        right: 56,
      }}
    ></div>
  </>
}
)
export default App;
