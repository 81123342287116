import { Content } from "./Content";
import { NotificationQueue } from "../NotificationQueue";
import FlipMove from "react-flip-move";
import { QueueContent } from "../../api";
import React from "react";
import { EndIsNear, SessionModel, TransitionPlayerScaleFactor } from "../App";
import QRCode from "react-qr-code";
import { useVMvalue } from "../vm/useVM";

type NotificationContent = {
  content: QueueContent;
  caption: string;
  emoji: string;
  key: number;
};

export const Notifications = React.memo(
  ({ model }: { model: SessionModel }) => {
    const upNext = useVMvalue(model.upNext)[0] as QueueContent | undefined;

    const endIsNear = React.useContext(EndIsNear)

    const notificationQueue = React.useMemo(
      () => new NotificationQueue<NotificationContent>(3000),
      []
    );
    let queue = useVMvalue(notificationQueue.queueVM);

    React.useEffect(() => {
      let key = 0;
      let unsubscribe = model.addedSubject.subscribe((content) =>
        notificationQueue.push(
          {
            content,
            caption: "New video added",
            emoji: "🆕",
            key: ++key,
          },
          key.toString()
        )
      );

      let unsubscribe2 = model.votedSubject.subscribe((content) =>
        notificationQueue.push(
          {
            content,
            caption: "Vote",
            emoji: `${content.increment > 0 ? "👍" : "👎"}`,
            key: ++key,
          },
          key.toString()
        )
      );

      return () => {
        unsubscribe();
        unsubscribe2();
      };
    }, []);

    // fix broken flip types
    const FlipMoveClass = FlipMove as any;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minWidth: 300,
          justifyContent: "flex-end",
          alignItems: "flex-end",
          alignSelf: "flex-end",
        }}
      >
        {/* TODO: make it fixed width */}
        <FlipMoveClass
          style={{ display: 'flex', flexDirection: 'column' }}
          enterAnimation={{
            from: {
              transform: "translateY(-500px)",
              opacity: 0.1,
            },
            to: {
              transform: "",
            },
          }}
          leaveAnimation={{
            from: {
              transform: "",
            },
            to: {
              transform: "translateY(10px)",
              opacity: 0.1,
            },
          }}
        >
          {upNext && (
            <UpNextAndQr key={`upnext`} upNext={upNext} model={model} />
          )}
          {queue.map((c) => (
            <Content
              key={c.data.key}
              content={c.data.content}
              caption={c.data.caption}
              emoji={c.data.emoji}
            />
          ))}
          {/* {<Content
            key={1}
            content={{
              id: '', chatId: 123, descriptor: { type: 'ytb', id: '' }, thumbnail: {
                url: "",
                width: 100,
                height: 100,
              }, messageId: 1, title: 'asda asd a', rating: { positive: [], negative: [] }
            }}
            caption={"adasdasd asdasd asd"}
            emoji={'e'}
          />} */}
          <div style={{ width: 300 }} />
        </FlipMoveClass>
      </div>
    );
  }
);

export const UpNextAndQr = React.memo(
  React.forwardRef<
    HTMLDivElement,
    { upNext: QueueContent; model: SessionModel }
  >(({ upNext, model }: { upNext: QueueContent; model: SessionModel }, ref) => {
    const qrString = useVMvalue(model.metVM)?.settings?.qr;


    const [showQrPeriodically, setShowQr] = React.useState(false);

    React.useEffect(() => {
      if (qrString) {
        setShowQr(true);
        const interval = setInterval(() => {
          setShowQr((flip) => !flip);
        }, 5000);
        return () => clearInterval(interval);
      } else {
        setShowQr(false);
      }
    }, [qrString]);

    const endIsNear = React.useContext(EndIsNear)
    const flip = showQrPeriodically || (qrString && endIsNear)

    return (
      <div
        ref={ref}
        style={{
          display: 'flex',
          flexDirection: 'column',
          willChange: "transform",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          alignSelf: "flex-end",
          zIndex: 2,

        }}
      >
        <div style={{
          willChange: "transform",
          transition: "transform 300ms ease-in-out",
          transform: endIsNear ? `scale(${TransitionPlayerScaleFactor})` : undefined,
          transformOrigin: 'top right'
        }}>
          <div style={{ opacity: 0 }}>
            <Content content={upNext} caption={"Up next"} emoji="asdasd" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              position: "absolute",
              right: 0,
              inset: 0,
              transform: qrString && flip ? "rotateY(-180deg)" : "",

              willChange: "transform",
              transition: "transform 300ms ease-in-out",
              transformStyle: "preserve-3d",
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                zIndex: 2,
                position: "absolute",
                inset: 0,
                backfaceVisibility: "hidden",
                WebkitPerspective: 0,
                WebkitBackfaceVisibility: "hidden",
                WebkitTransform: "rotateY(0deg) translate3d(0,0,0)",
                visibility: "visible",
                /* for firefox 31 */
                transform: "rotateY(0deg) translate3d(0,0,0)",
              }}
            >
              <Content
                key={`upnext_${upNext.id}`}
                content={upNext}
                caption={"Up next"}
                emoji="⏭"
              />
            </div>
            {qrString && (
              <div
                style={{
                  position: "absolute",
                  inset: 0,
                  top: 32,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1,
                  transform: "rotateY(180deg) translate3d(0,0,0)",
                  backfaceVisibility: "hidden",
                  WebkitPerspective: 0,
                  WebkitBackfaceVisibility: "hidden",
                  WebkitTransform: "rotateY(180deg) translate3d(0,0,0)",
                  visibility: "visible",
                }}
              >
                <QRCode
                  className="small-hide"
                  bgColor="rgba(15, 15, 15)"
                  fgColor="rgba(120, 120, 120)"
                  size={128}
                  value={qrString}
                  style={{
                    width: 128,
                    height: 128,
                    border: "solid rgba(15, 15, 15) 16px",
                    borderRadius: 16,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  })
);
