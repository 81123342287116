import { NotificationQueue } from "../NotificationQueue";
import FlipMove from "react-flip-move";
import { Message } from "../../api";
import { useVMvalue } from "../vm/useVM";
import React, { forwardRef } from "react";
import { SessionModel } from "../App";
import { layout } from "../utils/layout";

export const Messages = React.memo(({ model }: { model: SessionModel }) => {
  const notificationQueue = React.useMemo(
    () => new NotificationQueue<Message>(undefined, -1),
    []
  );
  let queue = useVMvalue(notificationQueue.queueVM);

  React.useEffect(() => {
    const sub1 = model.messagesPipeSubject.subscribe((m) => {
      notificationQueue.push(m, m.id);
    });
    const sub2 = model.playingContent.subscribe(() => {
      notificationQueue.clean();
    });
    return () => {
      sub1();
      sub2();
    };
  }, []);

  // fix broken flip types
  const FlipMoveClass = FlipMove as any;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: 400,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignSelf: "flex-start",
      }}
    >
      {/* TODO: make it fixed width */}
      <FlipMoveClass
        style={{ display: "flex", flexDirection: "column", width: 400 }}
        enterAnimation={{
          from: {
            transform: "translateY(500px)",
            opacity: 0.1,
          },
          to: {
            transform: "",
          },
        }}
        leaveAnimation={{
          from: {
            transform: "",
          },
          to: {
            transform: "translateY(-10px)",
            opacity: 0.1,
          },
        }}
        verticalAlignment="bottom"
      >
        {queue.map((c, index) => (
          <MessageView
            key={c.data.id}
            message={c.data}
            index={queue.length - 1 - index}
          />
        ))}
        {/* <MessageView
          key="test"
          index={3}
          message={{
            id: "test",
            user: { id: "test", name: "test" },
            text: "test",
          }}
        />

        <MessageView
          key="test2"
          index={2}
          message={{
            id: "test2",
            user: { id: "test2", name: "test2" },
            text: "test test test test test test test test test test test test test test test test test test ",
          }}
        />
        <MessageView
          key="test3"
          index={1}
          message={{
            id: "test3",
            user: { id: "test3", name: "test3" },
            text: "test test test test test ",
            image: {
              width: 480,
              height: 360,
              url: "https://i.ytimg.com/vi/HyHNuVaZJ-k/hqdefault.jpg",
            },
          }}
        />
        <MessageView
          key="test4"
          index={0}
          message={{
            id: "test",
            user: { id: "test", name: "test" },
            text: "test",
          }}
        /> */}
      </FlipMoveClass>
    </div>
  );
});

const MessageView = React.memo(
  forwardRef<HTMLDivElement, { message: Message; index: number }>(
    (props: { message: Message; index: number }, ref) => {
      const { message, index } = props;
      const content = React.useMemo(() => {
        const c = message.video || message.image;
        if (c) {
          const wh = layout([c.width, c.height], [400, 400], true);
          return {
            ...c,
            width: wh[0],
            height: wh[1],
          };
        }
      }, [message.image, message.video]);

      return (
        <div
          style={{
            willChange: "transform",
            alignSelf: "flex-start",
          }}
          ref={ref}
        >
          <div style={{ marginTop: 8 }} />
          <div
            style={{
              opacity: 1 - index / 5,
              transition: "opacity 500ms",
              display: "flex",
              flexDirection: "column",
              border: "solid var(--secondary) 2px",
              borderRadius: 16,
              background: "var(--primary)",
              padding: 16,
              color: "var(--bg)",
              overflow: "hidden",
              transform: "translateZ(0)",
            }}
          >
            <span style={{ fontWeight: 700, fontSize: "1.5em" }}>
              {message.user.name}
            </span>
            <span style={{ fontSize: "1.5em" }}>
              {!!message.text && <>{message.text}</>}
            </span>
            {content &&
              (message.image ? (
                <img
                  style={{
                    marginLeft: -16,
                    marginRight: -16,
                    marginBottom: -16,
                    marginTop: 16,
                  }}
                  width={content.width}
                  height={content.height}
                  src={content.url}
                />
              ) : message.video ? (
                <video
                  autoPlay={true}
                  muted={true}
                  loop={true}
                  style={{
                    marginLeft: -16,
                    marginRight: -16,
                    marginBottom: -16,
                    marginTop: 16,
                  }}
                  width={content.width}
                  height={content.height}
                  src={content.url}
                />
              ) : null)}
          </div>
        </div>
      );
    }
  )
);
