import { VM } from "./vm/VM";

export class NotificationQueue<T> {
  removeDelay?: number;
  direction = 1;
  constructor(removeDelay?: number, direction = 1) {
    this.removeDelay = removeDelay;
    this.direction = direction;
  }
  queueVM = new VM<{ data: T; key: string }[]>([]);
  push = (data: T, key: string) => {
    if (this.direction === 1) {
      this.queueVM.next([{ data, key }, ...this.queueVM.val]);
    } else {
      this.queueVM.next([...this.queueVM.val, { data, key }]);
    }
    if (this.removeDelay) {
      setTimeout(() => {
        this.queueVM.next(this.queueVM.val.filter((e) => e.key !== key));
      }, this.removeDelay);
    }
  };

  clean = () => {
    this.queueVM.next([]);
  };
}
