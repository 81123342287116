import React from "react";
export const BackgroundContext = React.createContext("var(--tg-theme-bg-color)")

export const Card = ({ children, style, onClick }: { children: any, style?: any, onClick?: React.MouseEventHandler<HTMLDivElement> }) => {
    return <div onClick={onClick} className={onClick ? "card" : undefined} style={{ display: 'flex', flexDirection: 'column', margin: '8px 16px', padding: 4, backgroundColor: "var(--tg-theme-secondary-bg-color)", borderRadius: 16, ...style }}>
        <BackgroundContext.Provider value="var(--tg-theme-secondary-bg-color)">
            {children}
        </BackgroundContext.Provider>
    </div>
}
