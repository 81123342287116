import React from "react";
import { io, Socket } from "socket.io-client";
import { QueueContent } from "../../api";
import { VM } from "../vm/VM";
import { useVMvalue } from "../vm/useVM";
import { ContentQueue } from "./ContentQueue";
import Cookies from "js-cookie";

type TgWebAppInitData = { chat?: { id: number }, user: { id: number }, start_param?: string } & unknown;

export class SessionModel {
  readonly tgWebApp: TgWebAppInitData;
  readonly queue = new VM<QueueContent[] | undefined>(undefined);

  private socket: Socket;

  private emit = (ev: string, ...args: any[]) => {
    console.log(ev, args);
    this.socket.emit(ev, ...args);
  };

  constructor(private params: { initDataUnsafe: TgWebAppInitData, initData: string }) {
    this.tgWebApp = params.initDataUnsafe
    const endpoint =
      window.location.hostname.indexOf("localhost") >= 0
        ? "http://localhost:5001"
        : "https://www.plst.app";

    this.socket = io(endpoint, {
      transports: ["websocket"],
      query: {
        tgQueue: true,
        initData: params.initData,
        initDataUnsafe: encodeURIComponent(JSON.stringify(params.initDataUnsafe))
      },
    });

    this.socket.onAny((...e) => {
      console.log(e);
    });
    this.socket.on("connect", () => {
      console.log("connect");
      this.emit("reg", Number(params.initDataUnsafe.start_param));
    });

    this.socket.on("queueUpdated", (queue: QueueContent[]) => {
      this.queue.next(queue);
    });

    this.socket.on("chatMigratedTo", (to: number) => {
      // looks like it is not working since its the same bot
      // (window as any).Telegram.WebApp?.openTelegramLink(`https://t.me/plstappbot/plstapp?startapp=${to}&startApp=${to}`);
      (window as any).Telegram.WebApp?.close()
    });

    Cookies.set("user_id", params.initDataUnsafe.user.id.toString(), { expires: 1000 * 60 * 60 * 24 * 30 })
  }

  like = (contentId: string, increment: 1 | -1) => {
    this.emit("like", contentId, increment);
  };

  skip = (contentId: string) => {
    this.emit("skip", contentId);
  };

  del = (contentId: string) => {
    this.emit("delete", contentId);
  };
}

export const ModelContext = React.createContext<SessionModel | undefined>(
  undefined
);

export const UserContext = React.createContext<number | undefined>(undefined);

export const TgQueue = ({ model }: { model: SessionModel }) => {
  const queue = useVMvalue(model.queue);
  return (
    <ModelContext.Provider value={model}>
      <UserContext.Provider
        value={model.tgWebApp.user.id}
      >
        {queue && <ContentQueue queue={queue} />}
      </UserContext.Provider>
    </ModelContext.Provider>
  );
};

