import React, { forwardRef, useContext } from "react";
import { QueueContent } from "../../api";
import { layout } from "../utils/layout";
import { ModelContext, UserContext } from "./TgQueue";
import FlipMove from "react-flip-move";
import { Card } from "../uikit/Card";
import { ListItem } from "../uikit/ListItem";

export const ContentQueue = React.memo(
  ({ queue }: { queue: QueueContent[] }) => {
    const FlipMoveClass = FlipMove as any;
    if (!queue.length) {
      return <FallBack
        title="Playlist is empty - send Youtube video to the chat"
        subtitle="tip: type @vid Imagine Dragons (or any other artist you like) and pick a video to play" />
    }
    return (
      <FlipMoveClass>
        {queue
          .map((c, index, array) => {
            let prev: QueueContent | undefined = array[index - 1];
            // ignore playing
            prev = prev?.playing ? undefined : prev;
            const firstNew = !c.playing && !c.played && !prev;
            const firstHistory = !c.playing && c.played && !prev?.played;
            return [
              (c.playing || firstNew || firstHistory) && (
                <div
                  key={
                    "title_" +
                    (c.playing
                      ? "playing"
                      : firstNew
                        ? "firstNew"
                        : "firstHistory ")
                  }
                  style={{
                    color: "var(--tg-theme-text-color)",
                    margin: 16,
                    marginBottom: 8,
                    fontSize: "1.5em",
                  }}
                >
                  {c.playing && "Now playing"}
                  {firstNew && (
                    <>
                      New{" "}
                      <span style={{ color: "var(--tg-theme-hint-color)" }}>sorrted by likes</span>
                    </>
                  )}
                  {firstHistory && (
                    <>
                      Already played{" "}
                      <span style={{ color: "var(--tg-theme-hint-color)" }}>shuffling by likes</span>
                    </>
                  )}
                </div>
              ),
              <Content key={c.id} content={c} />,
            ].filter(Boolean);
          })
          .flat(1)}
      </FlipMoveClass>
    );
  }
);

export const Content = React.memo(
  forwardRef<HTMLDivElement, any>(
    (
      {
        content,
        firstNew,
        firstHistory,
      }: {
        content: QueueContent;
        firstNew?: boolean;
        firstHistory?: boolean;
      },
      ref
    ) => {
      const thumb = React.useMemo(() => {
        const t = content.thumbnail;
        if (t) {
          const wh = layout([t.width, t.height], [96, 96]);
          return { ...t, width: wh[0], height: wh[1] };
        }
      }, [content]);

      const onContentClick = React.useCallback(() => {
        const wa = (window as any).Telegram?.WebApp
        if (content.descriptor.type === 'ytb') {
          wa?.openLink(`https://youtu.be/${content.descriptor.id}`)
        }
      }, [])

      const onUserClick = React.useCallback((e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation()
        const wa = (window as any).Telegram?.WebApp
        wa?.openTelegramLink(`https://t.me/c/${content.chatId.toString().slice(4)}/${content.messageId}`)
      }, [])

      return (
        <div
          ref={ref}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: '8px 16px'
          }}
          onClick={onContentClick}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              borderRadius: 8,
              overflow: "hidden",
              flexShrink: 0,
            }}
          >
            {thumb ? (
              <img
                src={thumb.url}
                style={{
                  width: thumb.width,
                  height: thumb.height,
                }}
                width={thumb.width}
                height={thumb.height}
              />
            ) : (
              <div style={{ width: 100, height: 75, background: "var(--tg-theme-bg-color)" }} />
            )}
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", margin: '4px 16px' }}
          >
            <span style={{ color: "var(--tg-theme-text-color)", paddingBottom: 4 }}>
              {content.title}
            </span>
            {!!content.userName && <span onClick={onUserClick} style={{ color: "var(--tg-theme-hint-color)", fontSize: '0.8em', padding: '4px 0px' }}>
              {content.userName}
            </span>}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 8,
              }}
            >
              <Buttons content={content} />
            </div>
          </div>
        </div>
      );
    }
  )
);

const Buttons = React.memo((props: { content: QueueContent }) => {
  const { content } = props
  const userId = useContext(UserContext)
  const model = React.useContext(ModelContext);
  const [likeProgress, setLikeProgress] = React.useState(false);
  const likeAny = React.useCallback(
    (increment: -1 | 1) => {
      // TODO: extract/inject haptic provider
      (window as any).Telegram?.WebApp.HapticFeedback.impactOccurred('light');
      model?.like(content.id, increment);
      setLikeProgress(true);
      const timeout = setTimeout(() => setLikeProgress(false), 5000);
      return () => clearTimeout(timeout);
    },
    [content.descriptor.id, model]
  );
  const like = React.useCallback(() => {
    likeAny(1);
  }, [likeAny]);
  const dislike = React.useCallback(() => {
    likeAny(-1);
  }, [likeAny]);
  // Soooo optimistic
  React.useEffect(() => {
    setLikeProgress(false);
  }, [content]);

  const [skipProgress, setSkipProgress] = React.useState(false);
  const skip = React.useCallback(() => {
    // TODO: extract/inject haptic provider
    (window as any).Telegram?.WebApp.HapticFeedback.impactOccurred('light');
    model?.skip(content.id);
    setSkipProgress(true);
    const timeout = setTimeout(() => setSkipProgress(false), 5000);
    return () => clearTimeout(timeout);
  }, [content.descriptor]);

  const [deleteProgress, setDeleteProgress] = React.useState(false);
  const del = React.useCallback(() => {
    // TODO: extract/inject haptic provider
    (window as any).Telegram?.WebApp.HapticFeedback.impactOccurred('light');
    model?.del(content.id);
    setDeleteProgress(true);
    const timeout = setTimeout(() => setDeleteProgress(false), 5000);
    return () => clearTimeout(timeout);
  }, [content.descriptor]);
  return <>
    <Button
      onClick={like}
      text={`👍 ${content.rating.positive.length || ""}`}
      disabled={likeProgress}
      type={
        userId && content.rating.positive.includes(userId)
          ? "accent"
          : "primary"
      }
    />
    <Button
      onClick={dislike}
      text={`👎 ${content.rating.negative.length || ""}`}
      disabled={likeProgress}
      type={
        userId && content.rating.negative.includes(userId)
          ? "accent"
          : "primary"
      }
    />
    {content.playing &&
      content.rating.negative.length >
      content.rating.positive.length && (
        <Button onClick={skip} text={`⏭`} disabled={skipProgress} />
      )}
    {!content.playing &&
      content.userId &&
      userId &&
      userId === content.userId && (
        <Button onClick={del} text={`🚮`} disabled={deleteProgress} />
      )}
  </>
})

export const Button = React.memo(
  ({
    text,
    onClick,
    disabled,
    type,
  }: {
    text: string;
    onClick: () => void;
    disabled?: boolean;
    type?: "primary" | "accent";
  }) => {
    type = type ?? "primary";
    return (
      <span
        style={{
          minWidth: 48,
          textAlign: "center",
          padding: "8px 8px",
          marginRight: 8,
          background: type === "primary" ? "var(--tg-theme-secondary-bg-color)" : "var(--tg-theme-button-color)",
          borderRadius: 8,
          color: type === "primary" ? "var(--tg-theme-hint-color)" : "var(--tg-theme-button-text-color)",
          fontWeight: "600",
          opacity: disabled ? 0.8 : 1,
        }}
        onClick={(e) => {
          e.stopPropagation()
          onClick()
        }}
      >
        {text}
      </span>
    );
  }
);

export const FallBack = ({ title, subtitle }: { title: string, subtitle: string }) => <Card style={{ marginTop: 16 }}><ListItem
  titile={title}
  titleStyle={{ whiteSpace: 'pre-wrap' }}
  subtitle={subtitle} /></Card>