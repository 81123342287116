import React, { forwardRef } from "react";
import { QueueContent } from "../../api";
import { layout } from "../utils/layout";

export const Content = React.memo(
  forwardRef<
    HTMLInputElement,
    { content: QueueContent; caption: string; emoji?: string }
  >(
    (
      props: { content: QueueContent; caption: string; emoji?: string },
      ref
    ) => {
      const { content } = props;
      const thumb = React.useMemo(() => {
        const t = content.thumbnail;
        if (t) {
          const wh = layout([t.width, t.height], [200, 200]);
          return { ...t, width: wh[0], height: wh[1] };
        }
      }, [content]);

      return (
        <div
          className="small-hide"
          style={{
            display: 'flex',
            willChange: "transform",
            marginTop: 32,
            alignSelf: "flex-end",
          }}
          ref={ref}
        >
          <div
            className="border-appear-animation"
            style={{
              display: "flex",
              position: "relative",
              color: "var(--secondary)",
              fontWeight: 500,
              fontSize: "1em",
              border: "solid var(--secondary) 2px",
              borderRadius: 16,
              overflow: "hidden",
              willChange: "transform",
            }}
          >
            {thumb && (
              <img
                src={thumb.url}
                style={{
                  width: thumb.width,
                  height: thumb.height,
                }}
                width={thumb.width}
                height={thumb.height}
              />
            )}

            <div
              style={{
                display: "flex",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
              className="content-appear-animation"
            />

            <div
              className="text-background-appear-animation"
              style={{
                display: "flex",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                padding: 8,
                background: "rgba(0,0,0,.6)",
              }}
            >
              <div className="top-text-appear-animation">{props.caption}</div>
            </div>

            <div
              className="bottom-text-appear-animation"
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                padding: 8,
                background: "rgba(0,0,0,.6)",
              }}
            >
              {content.title}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: -16,
              right: -16,
              fontSize: "2em",
              textShadow: `
              2px 0 var(--secondary), -2px 0 var(--secondary), 0 2px var(--secondary), 0 -2px var(--secondary),
               1px 1px var(--secondary), -1px -1px var(--secondary), 1px -1px var(--secondary), -1px 1px var(--secondary)`,
            }}
          >
            {props.emoji}
          </div>
        </div>
      );
    }
  )
);
