export const layout = (wh: number[], maxWH: number[], upscale?: boolean) => {
  if (wh[0] < maxWH[0] && wh[1] < maxWH[1] && !upscale) {
    return [...wh, 1];
  }
  let ww = [wh[0], maxWH[0]];
  let hh = [wh[1], maxWH[1]];
  let target = maxWH[0] / wh[0] < maxWH[1] / wh[1] ? ww : hh;
  let m = target[1] / target[0];
  return [wh[0] * m, wh[1] * m, m];
};
