import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { TgQueue, SessionModel } from "./tgQueue/TgQueue";
import { BindSession } from "./bindSession/BindSession";

const root = createRoot(document.getElementById("root")!);
if (window.location.pathname.startsWith("/tg/")) {
  let { initData, initDataUnsafe, ready } = (window as any).Telegram.WebApp;
  ready();

  const model = new SessionModel(
    { initData, initDataUnsafe }
  );
  const sub = model.queue.subscribe((q) => {
    if (q) {
      sub();
      root.render(<TgQueue model={model} />);
    }
  });
} else if (window.location.pathname === "/") {
  root.render(<BindSession />);
} else {
  root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
