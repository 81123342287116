import React from "react";
import { io, Socket } from "socket.io-client";
import { VM } from "../vm/VM";
import { useVMvalue } from "../vm/useVM";

export class SessionModel {
  private socket: Socket;

  code = new VM<string | undefined>(undefined);

  constructor() {
    const endpoint =
      window.location.hostname.indexOf("localhost") >= 0
        ? "http://localhost:5001"
        : window.location.origin;

    this.socket = io(endpoint, {
      transports: ["websocket"],
      query: {
        bindSession: true,
      },
    });

    this.socket.onAny((...e) => {
      console.log(e);
    });
    this.socket.on("code", (code) => {
      this.code.next(code);
    });
    this.socket.on("redirect", (path) => {
      window.location.href = path;
    });
    this.socket.on("disconnect", () => {
      this.code.next(undefined);
    });
  }
}

export const BindSession = () => {
  const model = React.useMemo(() => new SessionModel(), []);
  const code = useVMvalue(model.code);
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          justifyItems: "center",
          color: "rgba(110, 110, 110)",
          fontSize: "2em",
          margin: "auto",
          padding: 32,
        }}
      >
        <span style={{ color: "rgba(110, 110, 110)" }}>
          • add @plstappbot to Telegram chat
          <br />
        </span>
        • send code:{" "}
        {code ? (
          <span style={{ color: "rgba(200, 200, 200)" }}>
            <b>{code}</b>
          </span>
        ) : (
          <>Loading</>
        )}
      </div>
    </div>
  );
};
